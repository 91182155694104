import { render, staticRenderFns } from "./help-center.vue?vue&type=template&id=7daa29ba&scoped=true&lang=pug&"
import script from "./help-center.vue?vue&type=script&lang=js&"
export * from "./help-center.vue?vue&type=script&lang=js&"
import style0 from "./help-center.vue?vue&type=style&index=0&id=7daa29ba&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7daa29ba",
  null
  
)

export default component.exports